import Live from "../assets/images/Live.svg";
import Transcoder from "../assets/images/Transcoder.svg";
import Webinar from "../assets/images/Webinar.svg";
import MobileLive from "../assets/images/MobileLive.svg";
import MobileTranscoder from "../assets/images/MobileTranscoder.svg";
import MobileWebinar from "../assets/images/MobileWebinar.svg";

export const HEADER_CONTENT = [
  {
    title: `The next video standard
for all contents and devices.`,
    paragraph: `Jocoos is a technology company on a mission to develop and provide SaaS type software which is including video transcoding technology,
real time video, and instance video editing technology to be applied to video service on mobile and web environment.
OTT (Over The Top service), Real time broadcasting service, Live commerce, VR, AR,
and any video related business can be delivered easily by using our Product, FlipFlop.`,
    backgroundImg:
      process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
      "jocoos-home/images/BackgroundOne.png",
  },
  {
    title: `FlipFlop`,
    paragraph: `FlipFlop is a SaaS suite for embracing video technology into your product.
Starting from simply transcoding videos to live streaming for various purposes.
Our goal is to provide a simple way for you to incorporate video technology for your own needs.
Use FlipFlop to delegate video realted issues on the cloud and concentrate on your own business logic.
Check out the services FlipFlop has to offer.`,
    backgroundImg:
      process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
      "jocoos-home/images/BackgroundTwo.png",
  },
  //   {
  //     title: `MyBeautip`,
  //     paragraph: `MyBeautip introduced live streaming e-commerce to Korea by using FlipFlop.
  // It is a community driven online shopping app for cosmetics.
  // MyBeautip provides a platform for sharing information about the products they use by posting video reviews or
  // doing a live video stream and letting viewers buy the related product while watching the video.`,
  //     backgroundImg:
  //       process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
  //       "jocoos-home/images/BackgroundThree.jpg",
  //   },
  {
    title: `Vicollo`,
    paragraph: `Vicollo leverages the FlipFlop-Lite's latest media streaming technology, which is driving the revolution in media
streaming, to provide a video meeting platform for the future.
Superior picture quality, sound quality, user-friendliness, security and platform support give users a new level of
video meeting experience.
Experience future communications that make the video meeting experience richer and smoother with Vicollo.
Better than ever!`,
    backgroundImg:
      process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
      "jocoos-home/images/BackgroundFour.jpeg",
  },
];

export const MOBILE_HEADER_CONTENT = [
  {
    title: `The
next video
standard
for all contents
and devices.`,
    paragraph: `Jocoos is a video technology company on a
mission to develop and provide SaaS
video software supporting video transcoding,
real time streaming, and instance video editing`,
    backgroundImg:
      process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
      "jocoos-home/images/BackgroundOne.png",
  },
  {
    title: `FlipFlop`,
    paragraph: `FlipFlop is a SaaS suite for embracing video
technology into your product.
Starting from simply transcoding videos to live
streaming for various purposes for you to
incorporate video technology for your
own needs.`,
    backgroundImg:
      process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
      "jocoos-home/images/BackgroundTwo.png",
  },
  //   {
  //     title: `MyBeautip`,
  //     paragraph: `MyBeautip is a community driven online shopping
  // app for cosmetics. MyBeautip provides a platform
  // for sharing information about the products they
  // use by posting video reviews or doing a live video
  // stream and letting viewers buy the related product
  // while watching the video.`,
  //     backgroundImg:
  //       process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
  //       "jocoos-home/images/BackgroundThree.jpg",
  //   },
  {
    title: `Vicollo`,
    paragraph: `Vicollo leverages the FlipFlop-Lite's latest media streaming technology, which is driving the revolution in media streaming, to provide a video meeting platform for the future.
Better than ever!`,
    backgroundImg:
      process.env.NEXT_PUBLIC_CF_PUBLIC_URL +
      "jocoos-home/images/BackgroundFour.jpeg",
  },
];

export const PRODUCT_CONTENT = [
  {
    title: `Transcoder`,
    paragraph: `FlipFlop transcoder makes video
to be played on every mobile device.
FlipFlop transcoder makes video
to be compressed extremely as well as`,
    image: Transcoder,
  },
  {
    title: `Live`,
    paragraph: `FlipFlop real-time streaming is
the new conceptual real-time streaming
and video playback technology.`,
    image: Live,
  },
  {
    title: `Webinar`,
    paragraph: `FlipFlop Webinar supports online
interactive forum with broadcasting
module. (OBS, vMix, XSplit)
Vicollo Webinar supports high resolution
online low latency video meeting on web.`,
    image: Webinar,
  },
];

export const MOBILE_PRODUCT_CONTENT = [
  {
    title: `Transcoder`,
    paragraph: `FlipFlop transcoder makes video
to be played on every mobile device.`,
    image: MobileTranscoder,
  },
  {
    title: `Live`,
    paragraph: `FlipFlop real-time streaming is the
new conceptual real-time streaming`,
    image: MobileLive,
  },
  {
    title: `Webinar`,
    paragraph: `FlipFlop Webinar supports online
interactive forum with broadcasting module. (OBS)
Vicollo Webinar supports high resolution online low latency video meeting on web.`,
    image: MobileWebinar,
  },
];

export const INVESTOR_LOGOS_URL =
  process.env.NEXT_PUBLIC_CF_PUBLIC_URL + "investors/investorLogoList.json";

export const CLIENT_LOGOS_URL =
  process.env.NEXT_PUBLIC_CF_PUBLIC_URL + "clients/clientLogoList.json";

export const FOOTER_CONTENT = `2F, 16-12 Yanghwa-ro 8-gil, mapo-gu, Seoul, 04044, Republic of Korea
support@jocoos.com
+82-70-4827-0981`;

export const MOBILE_FOOTER_CONTENT = `2F, 16-12 Yanghwa-ro 8-gil, mapo-gu,
Seoul, 04044, Republic of Korea
support@jocoos.com
+82-70-4827-0981
`;

export const MYBEAUTIP_CONTENT = {
  title: `솔직한 라이브 뷰티 커머스,
마이뷰팁`,
  subTitle: `누구나 쉽게 뷰티를 즐기고 공유하는 뷰티 라이브 커머스`,
  paragraph: `실시간으로 시청자와 소통하는 라이브!
라이브와 함께 가장 빠르게 상품구매!
솔직한 뷰티 리뷰로 나를 표현하는 커뮤니티!`,
};

export const MOBILE_MYBEAUTIP_CONTENT = {
  title: `마이뷰팁`,
  subTitle: `솔직한 라이브 뷰티 커머스`,
  paragraph: `실시간으로 시청자와 소통하는 라이브!
라이브와 함께 가장 빠르게 상품구매!
솔직한 뷰티 리뷰로 나를 표현하는 커뮤니티!`,
};

export const SITE_URL = "http://jocoos.com/";
export const SITE_NAME = "Jocoos";
export const SITE_DESC = `Jocoos is a technology company on a mission to develop and provide SaaS type software which is including video transcoding technology, real time video,
and instance video editing technology to be applied to video service on mobile and web environment.
OTT (Over The Top service), Real time broadcasting service, Live commerce, VR, AR, and any video related business can be delivered easily by using our Product, FlipFlop.`;
export const MYBEAUTIP_SITE_DESC = `누구나 쉽게 뷰티를 즐기고 공유하는 뷰티 라이브 커머스
실시간으로 시청자와 소통하는 라이브!
라이브와 함께 가장 빠르게 상품구매!
솔직한 뷰티 리뷰로 나를 표현하는 커뮤니티!
`;

export const MOBILE_BREAK_POINT = 1300;
export const MOBILE_CONTENT_MAX_WIDTH = 290;
export const MOBILE_CONTENT_MIN_HEIGHT = 640;
export const CONTENT_MAX_WIDTH = 1200;

export const SLIDE_CONTENT_MIN_HEIGHT = 268;
export const MOBILE_SLIDE_CONTENT_MIN_HEIGHT = 285;
export const COMPANY_INFO_URL =
  process.env.NEXT_PUBLIC_CF_PUBLIC_URL + "home/companyInfo.json";
