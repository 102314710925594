import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import styled, { css as styledCss } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderContent from "./SliderContent";
import Button from "../components/Button";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";
import transition from "../utils/style/transitions";
import Triangle from "../components/Triangle";
import {
  CONTENT_MAX_WIDTH,
  MOBILE_BREAK_POINT,
  MOBILE_CONTENT_MAX_WIDTH,
  MOBILE_CONTENT_MIN_HEIGHT,
  MOBILE_SLIDE_CONTENT_MIN_HEIGHT,
  SLIDE_CONTENT_MIN_HEIGHT,
} from "../config/config";

export default function HomeSlider({ contents, className }) {
  const [beforeActive, setBeforeActive] = useState(0);
  const [afterActive, setAfterActive] = useState(0);

  const slider = useRef(null);

  const onMouseOverHandle = useCallback((number) => () => {
    if (beforeActive !== number) {
      slider.current.slickGoTo(number);
      slider.current.slickPause();
    }
  });

  const onMouseLeaveHandle = useCallback(() => {
    slider.current.slickPlay();
  });
  const changeActive = useCallback(
    (number) => () => {
      slider.current.slickGoTo(number);
    },
    [beforeActive]
  );
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    beforeChange: (current, next) => {
      setBeforeActive(next);
      console.log(next);
    },
    afterChange: (current) => {
      setAfterActive(current);
    },
  };
  return (
    <S.Wrap className={className}>
      <Slider ref={slider} {...settings}>
        {contents.map((content) => (
          <SliderContent key={content.title} content={content} />
        ))}
      </Slider>

      <S.ScrollDown>
        <span>scroll down</span>
      </S.ScrollDown>
      <Triangle color="white" />

      <S.ContentContainer>
        <S.ContentWrap>
          <S.Content>
            {beforeActive == afterActive && (
              <>
                <Title color="white" size="medium">
                  {contents[beforeActive].title}
                </Title>
                <Paragraph color="white" size="medium">
                  {contents[beforeActive].paragraph}
                </Paragraph>
              </>
            )}
          </S.Content>
          <div>
            <S.IndicatorsWrap activeNumber={beforeActive}>
              <S.Indicators onClick={changeActive(0)}>
                <div />
              </S.Indicators>
              <S.Indicators onClick={changeActive(1)}>
                <div />
              </S.Indicators>
              {/* <S.Indicators onClick={changeActive(2)}>
                <div />
              </S.Indicators> */}
              <S.Indicators onClick={changeActive(2)}>
                <div />
              </S.Indicators>
            </S.IndicatorsWrap>

            <S.ButtonGroup>
              <a
                href="https://www.flipflop.tv/"
                rel="noopener noreferrer"
                target="_blank"
                onMouseMove={onMouseOverHandle(1)}
                onMouseLeave={onMouseLeaveHandle}
              >
                <Button
                  color="whiteYellow"
                  size="medium"
                  focus={beforeActive == 1}
                >
                  FlipFlop
                </Button>
              </a>
              {/* <a
                href="https://mybeautip.com/"
                rel="noopener noreferrer"
                target="_blank"
                onMouseMove={onMouseOverHandle(2)}
                onMouseLeave={onMouseLeaveHandle}
              >
                <Button
                  color="whitePink"
                  size="medium"
                  focus={beforeActive == 2}
                >
                  MyBeautip
                </Button>
              </a> */}
              <a
                href="https://vicollo.live/"
                rel="noopener noreferrer"
                target="_blank"
                onMouseMove={onMouseOverHandle(2)}
                onMouseLeave={onMouseLeaveHandle}
              >
                <Button
                  color="whiteBlue"
                  size="medium"
                  focus={beforeActive == 2}
                >
                  Vicollo
                </Button>
              </a>
            </S.ButtonGroup>
          </div>
        </S.ContentWrap>
      </S.ContentContainer>
    </S.Wrap>
  );
}

HomeSlider.propTypes = {
  contents: PropTypes.array.isRequired,
};

const S = {};

S.ButtonGroup = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
`;

S.Wrap = styled.div`
  position: relative;
`;

S.ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    min-height: ${MOBILE_CONTENT_MIN_HEIGHT}px;
  }
`;

S.ContentWrap = styled.div`
  width: ${CONTENT_MAX_WIDTH}px;
  overflow-wrap: break-word;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: ${MOBILE_CONTENT_MAX_WIDTH}px;
    margin: 0 1.5rem;
  }
`;

S.Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${CONTENT_MAX_WIDTH}px;
  margin-bottom: 5px;
  height: 300px;
  & h1 {
    width: 100%;
    will-change: opacity;
    animation: ${transition.fadeInUp} 1s ease normal backwards;
    margin-bottom: 40px;
  }
  & p {
    width: 100%;
    will-change: opacity;
    opacity: 0.7;
    animation: ${transition.fadeInUpShadow} 1s ease normal backwards;
    animation-delay: 0.5s;
  }
  min-height: ${SLIDE_CONTENT_MIN_HEIGHT}px;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: ${MOBILE_CONTENT_MAX_WIDTH}px;
    justify-content: flex-end;
    min-height: ${MOBILE_SLIDE_CONTENT_MIN_HEIGHT}px;
    & h1 {
      margin-bottom: 13px;
    }
  }
`;

S.Tool = styled.div`
  width: 100vw;
`;

S.IndicatorsWrap = styled.div`
  margin: 18px 0 28px 0;
  display: flex;
  height: 1rem;
  width: 7.5rem;
  & > div > div {
    background: #fff;
  }

  ${(props) => styledCss`
      & > div:nth-child(${props.activeNumber + 1}) > div {
        opacity: 1;
        background: ${(props) => {
          if (props.activeNumber === 1) {
            return "#f8a600";
          } else if (props.activeNumber === 2) {
            return "#4c6fff";
          } else {
            return "#ffc41f";
          }
        }};
      }
  `}
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    margin-top: 23px;
  }
`;
S.Indicators = styled.div`
  display: flex;
  align-items: center;
  height: 1rem;
  cursor: pointer;

  & > div {
    width: 1.8125rem;
    height: 2px;
    opacity: 0.3;
    background: #fff;
    margin-right: 0.5rem;
  }
`;

S.ScrollDown = styled.div`
  position: absolute;
  font-size: 0.8125rem;
  color: white;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  &:after {
    content: "";
    margin: 0.7rem auto 0;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 20px;
    margin: auto;
    width: 1px;
    height: 40px;
    background: #fff;
    -webkit-animation: ${transition.slowDown} 1.5s linear infinite;
    animation: ${transition.slowDown} 1.5s linear infinite;
    z-index: 2;
    transform: rotate(180reg);
  }
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    display: none;
  }
`;
